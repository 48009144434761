<template>
  <div id="konecta-filters">
    <AddEntity
      ref="addEntity"
      :version="bot && bot.activeVersion"
      :name="newLabel"
    />
    <IntentsPrompt
      v-if="addIntent"
      :toggle-prompt="toggleAddIntention"
      :name="newLabel"
      @onSave="savedIntent"
    />

    <KonaHelpTooltip
      :text="lang.helpTooltips.botMaker.condition[languageSelected]"
      position="left"
    />
    <!-- new condition -->
    <section class="konecta-filters">
      <div class="konecta-filters__settings">
        <vs-input
          class="konecta-filters__name"
          :label="lang.conditions.name[languageSelected]"
          v-model="condName"
          :disabled="readOnly"
        />
        <div class="mt-4">
          <label for="condition-tags" class="condition-tags-label">
            {{ lang.conditions.themes[languageSelected] }}
            <KonaHelpTooltip :text="tagDisclaimer" position="top" />
          </label>
          <multiselect
            id="condition-tags"
            v-model="condTags"
            :showLabels="false"
            :placeholder="lang.conditions.placeholderTheme[languageSelected]"
            :tag-placeholder="
              lang.conditions.themePlaceholder[languageSelected]
            "
            label="text"
            track-by="_id"
            :options="dialogsTags"
            :multiple="true"
            :taggable="true"
            @select="selectTag"
            @tag="addTag"
            @remove="removeTag"
            :disabled="readOnly"
          >
            <span slot="noOptions">
              {{ lang.conditions.noThemesOptions[languageSelected] }}
            </span>
          </multiselect>
        </div>
      </div>

      <SecondLevel :read-only="readOnly"/>

      <div class="konecta-filters__new-condition">
        <!-- new condition -->
        <div class="condition">
          <div class="condition__items">
            <div class="item-row">
              <!-- condition -->
              <div class="custom-select">
                <label for="condition-select">{{
                  lang.conditions.new[languageSelected]
                }}</label>
                <multiselect
                  :key="`multiselect-${key.getTime()}`"
                  :loading="!showConditions"
                  :disabled="!showConditions || readOnly"
                  v-model="condition"
                  :options="conditionOptions"
                  :optionHeight="25"
                  :max-height="210"
                  group-label="title"
                  group-values="group"
                  :group-select="false"
                  :placeholder="
                    lang.conditions.search.placeholder[languageSelected]
                  "
                  track-by="text"
                  label="text"
                  :showLabels="false"
                  :searchable="true"
                  @select="optionChange()"
                  @search-change="updateNewLabel"
                  ref="multiselect"
                >
                  <span slot="noResult">
                    <div class="slot-container">
                      <p>
                        <feather-icon
                          icon="FrownIcon"
                          class="w-4 h-4 mr-3 float-left"
                        />
                        {{ lang.conditions.search.noResult[languageSelected] }}
                        <strong>“{{ newLabel }}”</strong>
                      </p>
                    </div>
                  </span>
                  <span slot="afterList">
                    <vs-divider />
                    <div class="slot-container">
                      <div class="actions">
                        <vs-button
                          size="small"
                          color="primary"
                          @click="createIntent"
                          id="new-intent"
                        >
                          {{
                            lang.conditions.search.newIntent[languageSelected]
                          }}
                        </vs-button>
                        <vs-button
                          size="small"
                          color="primary"
                          @click="createEntity"
                          id="new-entity"
                        >
                          {{
                            lang.conditions.search.newEntity[languageSelected]
                          }}
                        </vs-button>
                      </div>
                    </div>
                  </span>
                </multiselect>
              </div>

              <!-- <div class="custom-select">
                <label for="condition-select">
                  {{ lang.conditions.new[languageSelected] }}
                </label>
                <select
                  id="condition-select"
                  v-if="showConditions"
                  v-model="condition"
                  @change="optionChange()"
                >
                  <optgroup
                    v-if="item.group && item.group.length > 0"
                    v-for="(item, index) in conditionOptions"
                    :key="index"
                    :label="item.title"
                  >
                    <option
                      v-for="(group, index) in item.group"
                      :key="index"
                      :value="group"
                    >
                      {{ group && group.text }}
                    </option>
                  </optgroup>
                </select>
              </div>-->

              <!-- vuesax condition blinking -->
              <!-- <vs-select
                v-if="showConditions"
                :label="lang.conditions.new[languageSelected]"
                v-model="condition"
                @change="optionChange()"
                id="condition-select"
              >
                <div :key="index" v-for="(item, index) in conditionOptions">
                  <vs-select-group
                    class="condition-select"
                    :title="item.title"
                    v-if="item.group && item.group.length > 0"
                  >
                    <vs-select-item
                      :key="index"
                      :value="group"
                      :text="group && group.text"
                      v-for="(group, index) in item.group"
                    />
                  </vs-select-group>
                </div>
              </vs-select>-->

              <!-- binary CV type -->
              <!-- <vs-select
                class="cv-type"
                v-if="
                  showConditions &&
                    condition &&
                    condition.type === 'type' &&
                    condition.typeOp === 2
                "
                v-model="cvType"
                :label="lang.conditions.type[languageSelected]"
              >
                <vs-select-item
                  :value="cvOption"
                  :text="cvOption"
                  :key="cvOption"
                  v-for="cvOption in CV_COMP_TYPES"
                />
              </vs-select>-->
              <div
                v-if="
                  showConditions &&
                  condition &&
                  condition.type === 'type' &&
                  condition.typeOp === 2
                "
              >
                <label>{{ lang.conditions.type[languageSelected] }}</label>
                <multiselect
                  class="cv-type"
                  v-model="cvType"
                  :options="CV_COMP_TYPES"
                  :searchable="false"
                  :show-labels="false"
                  placeholder
                  :optionHeight="25"
                  :disabled="readOnly"
                />
              </div>

              <!-- entity value -->
              <div
                v-if="condition && condition.type === 'entity'"
                class="custom-select"
              >
                <label for="sel-ent">
                  {{ lang.conditions.value[languageSelected] }}
                </label>
                <!-- <select id="sel-ent" v-model="customValue">
                  <option
                    v-for="entity in getEntityValues(condition.id)"
                    :key="entity._id"
                    :value="entity.value"
                    >{{ entity.value }}</option
                  >
                </select>-->
                <multiselect
                  id="sel-ent"
                  v-model="customValue"
                  :options="getEntityValues(condition.id)"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="false"
                  :placeholder="
                    lang.conditions.entities.placeholder[languageSelected]
                  "
                  :disabled="readOnly"
                />
                <!-- <multiselect
                  id="sel-ent"
                  v-model="customValue"
                  track-by="_id"
                  label="value"
                  :placeholder="
                    lang.conditions.entities.placeholder[languageSelected]
                  "
                  :options="getEntityValues(condition.id)"
                  :show-labels="false"
                  :allow-empty="false"
                  :optionHeight="25"
                />-->
                <!-- @select="getEntityValue" -->
              </div>

              <!-- regex value || unary CV value -->
              <vs-input
                v-if="
                  condition &&
                  (condition.type === 'match' ||
                    (condition.type === 'type' && condition.typeOp === 1))
                "
                :label="lang.conditions.value[languageSelected]"
                v-model="customValue"
                :danger="customValueError"
                :danger-text="
                  lang.conditions.messages.error.emptyCustomValue.text[
                    languageSelected
                  ]
                "
                :disabled="readOnly"
              />
            </div>

            <div class="item-row">
              <!-- binary CV value 1 -->
              <vs-input
                v-if="
                  condition &&
                  condition.type === 'type' &&
                  condition.typeOp === 2 &&
                  cvType
                "
                :label="lang.conditions.value[languageSelected]"
                v-model="customValue"
                :danger="customValueError"
                :danger-text="
                  lang.conditions.messages.error.emptyCustomValue.text[
                    languageSelected
                  ]
                "
                :disabled="readOnly"
              />

              <!-- binary CV value 2 -->
              <vs-input
                v-if="
                  condition &&
                  condition.type === 'type' &&
                  condition.typeOp === 2 &&
                  cvType &&
                  cvType !== 'ctx&input'
                "
                :label="lang.conditions.value[languageSelected]"
                v-model="customValue2"
                :danger="customValue2Error"
                :danger-text="
                  lang.conditions.messages.error.emptyCustomValue.text[
                    languageSelected
                  ]
                "
                :disabled="readOnly"
              />
            </div>
          </div>
        </div>

        <!-- add condition -->
        <vs-button
          class="add-button"
          color="success"
          icon-pack="feather"
          icon="icon-plus"
          :title="lang.conditions.buttons.add[languageSelected]"
          @click="addCondition"
          :disabled="readOnly"
          >{{ lang.conditions.buttons.add[languageSelected] }}</vs-button
        >
      </div>
    </section>

    <vs-divider v-if="conditions.length">
      <span v-if="!conditions.length">
        {{ lang.conditions.dividerText.empty[languageSelected] }}
      </span>
      <span v-if="conditions.length === 1"
        >1 {{ lang.conditions.dividerText.single[languageSelected] }}</span
      >
      <span v-if="conditions.length > 1">
        {{ conditions.length }}
        {{ lang.conditions.dividerText.plural[languageSelected] }}
      </span>
    </vs-divider>

    <!-- added conditions -->
    <section class="konecta-filters">
      <div
        class="konecta-filters__added-conditions"
        :class="{ 'has-conditions': conditions.length }"
      >
        <div
          v-for="(condition, index) in conditions"
          :key="index"
          class="condition"
        >
          <!-- operator -->
          <div v-if="index !== 0" class="condition__operator">
            <!-- <vs-select v-model="operators[index]" @change="updateConditions">
              <vs-select-item
                :key="opIndex"
                :value="op"
                :text="op"
                v-for="(op, opIndex) in OPS_OPTIONS[condition.type]"
              />
            </vs-select>-->

            <multiselect
              class="condition__operator"
              v-model="operators[index]"
              :options="OPS_OPTIONS[condition.type]"
              :searchable="false"
              :show-labels="false"
              placeholder
              :optionHeight="25"
              @select="updateConditions"
              :disabled="readOnly"
            />
          </div>

          <div class="condition__items">
            <div class="item-row">
              <vs-input
                v-if="conditions[index] && conditions[index].type"
                disabled
                :label="
                  lang.conditions.types[conditions[index].type][
                    languageSelected
                  ]
                "
                v-model="conditionsFilters.types[index].text"
              />
              <vs-input
                v-else
                disabled
                :danger="true"
                :danger-text="lang.conditions.notFound[languageSelected]"
                class="text-danger"
                v-model="conditions[index]"
              />

              <!-- binary CV type -->
              <!-- <vs-select
                class="cv-type"
                v-if="
                  condition &&
                    condition.type === 'type' &&
                    condition.typeOp === 2
                "
                v-model="cvTypes[index]"
                :label="lang.conditions.type[languageSelected]"
              >
                <vs-select-item
                  :value="cvOption"
                  :text="cvOption"
                  :key="cvOption"
                  v-for="cvOption in CV_COMP_TYPES"
                />
              </vs-select>-->
              <div
                class="cv-type"
                v-if="
                  condition &&
                  condition.type === 'type' &&
                  condition.typeOp === 2
                "
              >
                <label>{{ lang.conditions.type[languageSelected] }}</label>
                <multiselect
                  v-model="cvTypes[index]"
                  :options="CV_COMP_TYPES"
                  :searchable="false"
                  :show-labels="false"
                  placeholder
                  :optionHeight="25"
                  :disabled="readOnly"
                />
              </div>

              <!-- entity value -->
              <div v-if="condition.type === 'entity'" class="custom-select">
                <label for="sel-ent">
                  {{ lang.conditions.value[languageSelected] }}
                </label>
                <!-- <select id="sel-ent" v-model="values[index]">
                  <option
                    v-for="value in getEntityValues(condition.id)"
                    :key="value._id"
                    :value="value.value"
                    >{{ value.value }}</option
                  >
                </select>-->
                <!-- <vs-input id="sel-ent" v-model="values[index]" disabled /> -->

                <!-- :value="values[index]"
                  track-by="_id"
                label="value"-->
                <multiselect
                  id="sel-ent"
                  v-model="values[index]"
                  :options="getEntityValues(condition.id)"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="false"
                  :placeholder="
                    lang.conditions.entities.placeholder[languageSelected]
                  "
                  :disabled="readOnly"
                />
              </div>

              <!-- regex value || unary CV value -->
              <vs-input
                v-if="
                  condition.type === 'match' ||
                  (condition.type === 'type' &&
                    (condition.typeOp === 1 ||
                      CV_UNARY_FXS.includes(condition.text)))
                "
                :label="lang.conditions.value[languageSelected]"
                v-model="values[index]"
                :danger="customValueError"
                :danger-text="
                  lang.conditions.messages.error.emptyCustomValue.text[
                    languageSelected
                  ]
                "
                :disabled="readOnly"
              />
            </div>

            <div class="item-row">
              <!-- binary CV value 1 -->
              <vs-input
                v-if="
                  condition.type === 'type' &&
                  condition.typeOp === 2 &&
                  cvTypes[index]
                "
                :label="lang.conditions.value[languageSelected]"
                v-model="values[index]"
                :danger="customValueError"
                :danger-text="
                  lang.conditions.messages.error.emptyCustomValue.text[
                    languageSelected
                  ]
                "
                :disabled="readOnly"
              />

              <!-- binary CV value 2 -->
              <vs-input
                v-if="
                  condition.type === 'type' &&
                  condition.typeOp === 2 &&
                  cvTypes[index] &&
                  cvTypes[index] !== 'ctx&input'
                "
                :label="lang.conditions.value[languageSelected]"
                v-model="values2[index]"
                :danger="customValue2Error"
                :danger-text="
                  lang.conditions.messages.error.emptyCustomValue.text[
                    languageSelected
                  ]
                "
                :disabled="readOnly"
              />
            </div>

            <!-- remove condition -->
            <vs-button
              size="small"
              class="remove-button"
              color="danger"
              icon-pack="feather"
              icon="icon-x"
              :title="lang.conditions.buttons.remove[languageSelected]"
              @click="removeCondition(index)"
              :disabled="readOnly"
            />
            <!-- @mouseover="mouseOver"
            @mouseout="mouseOut"-->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import IntentsPrompt from '../../../../add-bot/Intent/IntentsPrompt.vue'
import BotMakerService from '../../../../../../../services/botMaker.service'
import { generateBot } from '../../../../../../../models/BotGenerator'

import {
  OPS_OPTIONS,
  CV_COMP_TYPES,
  CV_OPTIONS,
  CV_UNARY_FXS
} from '@/constants/constants'

export default {
  name: 'KonectaFilters',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      key: new Date(),
      CV_OPTIONS,
      OPS_OPTIONS,
      CV_COMP_TYPES,
      CV_UNARY_FXS,

      // new condition
      condition: '',
      customValue: '',
      customValue2: '',
      cvType: '',
      cvOption: null,

      customEntityValue: null,

      // added conditions
      conditions: [],
      operators: [],
      values: [],
      values2: [],
      cvTypes: [],

      customValueError: false,
      customValue2Error: false,

      showConditions: false,
      newLabel: '',
      addIntent: false
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect'),
    KonaHelpTooltip: () => import('@/components/KonaHelpTooltip.vue'),
    AddEntity: () => import('../../../../../entities/AddEntity.vue'),
    SecondLevel: () => import('./components/secondLevelAttention.vue'),
    IntentsPrompt
  },
  // watch: {
  //   customEntityValue(val) {
  //     this.customValue = val.value
  //   }
  // },
  methods: {
    ...mapActions('botMaker', ['GET_ENTITIES', 'GET_INTENTIONS']),
    ...mapMutations('botMaker', [
      'SET_CONDITIONS',
      'MAP_CONDITIONS_OPTIONS',
      'SET_CONDITION_NAME',
      'SET_CONDITION_TAG',
      'DELETE_CONDITION_TAG',
      'SET_CONDITION_TAGS'
    ]),
    ...mapActions('bots', ['GET_ACTIVE_VERSION_BOTS', 'GET_VERSION_INTENTS']),
    ...mapMutations('bots', ['SET_ADD_INTENT_PROMPT', 'SET_BOT']),

    // getEntityValue(val) {
    //   this.customValue = val.value
    // },
    resetCurrentCondition() {
      this.showConditions = false
      this.$nextTick(() => {
        this.customValue = ''
        this.customValue2 = ''
        this.condition = ''
        this.cvType = ''
        this.showConditions = true
      })
    },
    updateConditions() {
      this.SET_CONDITIONS({
        types: this.conditions,
        operators: this.operators,
        values: this.values,
        values2: this.values2,
        cvTypes: this.cvTypes
      })
    },
    hasCustomValue() {
      return (
        this.condition.type === 'intent' ||
        this.condition.type === 'entity' ||
        (this.condition.type === 'match' && this.customValue !== '') ||
        (this.condition.type === 'type' &&
          (this.customValue !== '' ||
            (this.condition.typeOp === 2 && this.customValue2 !== '')))
      )
    },
    addCondition() {
      if (this.hasCustomValue()) {
        const defaultOperator = OPS_OPTIONS[this.condition.type][0]
        this.operators.push(defaultOperator)
        this.conditions.push(this.condition)
        this.values.push(this.customValue)
        this.values2.push(this.customValue2)
        this.cvTypes.push(this.cvType)
        this.updateConditions()
        this.resetCurrentCondition()
        this.customValueError = false
        this.customValue2Error = false
      } else {
        this.customValueError = this.customValue === ''
        this.customValue2Error = this.customValue2 === ''
      }
      this.$emit('onFiltersQuantityChange', this.conditions.length)
    },
    removeCondition(index) {
      this.conditions.splice(index, 1)
      this.operators.splice(index, 1)
      this.values.splice(index, 1)
      this.values2.splice(index, 1)
      this.updateConditions()
      this.$emit('onFiltersQuantityChange', this.conditions.length)
    },
    optionChange() {
      this.customValue = ''
      this.customValue2 = ''
      this.cvType = ''
    },
    updateNewLabel(val) {
      this.newLabel = val
    },
    createIntent() {
      this.toggleAddIntention()
    },
    createEntity() {
      this.$nextTick(() => {
        this.$refs.addEntity.openPopup()
      })
    },
    toggleAddIntention() {
      this.addIntent = !this.addIntent
      this.SET_ADD_INTENT_PROMPT(this.addIntent)
    },
    async savedIntent() {
      try {
        await this.GET_INTENTIONS()
      } catch (error) {
        console.warn(error)
      }
    },
    removeDefaultTag() {
      const unclassTag = this.conditionTags.find(
        t => t.text === this.unclassifiedText
      )
      if (unclassTag) {
        this.DELETE_CONDITION_TAG(unclassTag)
      }
    },
    selectTag(tag) {
      this.SET_CONDITION_TAG(tag)
      this.removeDefaultTag()
    },
    addTag(tag) {
      const newTag = { text: tag }
      this.SET_CONDITION_TAG(newTag)
      this.removeDefaultTag()
    },
    removeTag(tag) {
      this.DELETE_CONDITION_TAG(tag)
    }

    // mouseOver(event) {
    //   event.target.parentElement.style.boxShadow =
    //     '0px 0px 5px 0px rgba(255,71,87,0.75)'
    // },
    // mouseOut(event) {
    //   event.target.parentElement.style.boxShadow = 'none'
    // }
  },
  watch: {
    '$store.state.botMaker.conditionsFilters'(val) {
      this.conditions = val.types
      this.operators = val.operators
      this.values = val.values
      this.values2 = val.values2
      this.cvTypes = val.cvTypes
    },
    '$store.state.botMaker.entities'() {
      this.MAP_CONDITIONS_OPTIONS()
    },
    '$store.state.botMaker.intentions'() {
      this.MAP_CONDITIONS_OPTIONS()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'bot',
      'intentions',
      'entities',
      'getEntityValues',
      'conditionsFilters',
      'conditionsGroups',
      'conditionName',
      'conditionTags',
      'dialogs'
    ]),
    unclassifiedText() {
      return this.lang.conditions.unclassified[
        this.bot.nlu.culture.split('-')[0]
      ]
    },
    conditionOptions() {
      const options = []

      if (this.conditionsGroups) {
        if (
          this.conditionsGroups.intentions &&
          this.conditionsGroups.intentions.length
        ) {
          options.push({
            title:
              this.lang.conditions.options.intentions[this.languageSelected],
            group: this.conditionsGroups.intentions
          })
        }

        if (
          this.conditionsGroups.entities &&
          this.conditionsGroups.entities.length
        ) {
          options.push({
            title: this.lang.conditions.options.entities[this.languageSelected],
            group: this.conditionsGroups.entities
          })
        }

        if (this.conditionsGroups.regex) {
          options.push({
            title: this.lang.conditions.options.regex[this.languageSelected],
            group: [this.conditionsGroups.regex]
          })
        }

        if (CV_OPTIONS.length) {
          options.push({
            title:
              this.lang.conditions.options.variables[this.languageSelected],
            group: CV_OPTIONS
          })
        }
      }

      return options
    },
    condName: {
      get() {
        return this.conditionName
      },
      set(val) {
        this.SET_CONDITION_NAME(val)
      }
    },
    condTags: {
      get() {
        return this.conditionTags
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
        this.SET_CONDITION_TAGS(this.conditionTags)
        if (this.conditionTags.length === 0) {
          const unclassTag = {
            text: this.unclassifiedText
          }
          this.SET_CONDITION_TAG(unclassTag)
        }
      }
    },
    dialogsTags() {
      // tags created for all dialogs
      const existingTags = this.dialogs.reduce(
        (acc, elem) => acc.concat(elem.tags),
        []
      )

      // filter duplicates tags (tags used in >1 dialog)
      const uniqueArray = existingTags.filter(
        (object, index) =>
          index ===
          existingTags.findIndex(
            obj => JSON.stringify(obj) === JSON.stringify(object)
          )
      )

      // filter unclassified tag
      const dialogsTags = uniqueArray.filter(
        t => t.text !== this.unclassifiedText
      )

      return dialogsTags
    },
    tagDisclaimer() {
      return `${this.lang.conditions.noTheme[this.languageSelected]} "${
        this.unclassifiedText
      }".`
    }
  },
  async mounted() {
    try {
      await this.GET_ENTITIES(this.bot.activeVersion)
      await this.MAP_CONDITIONS_OPTIONS()

      const botId = this.$route.query.botId
      if (botId) {
        let botResponse = await BotMakerService.getBot(botId)
        const generatedBot = generateBot(botResponse.data)
        this.SET_BOT(generatedBot)
        await this.GET_ACTIVE_VERSION_BOTS([
          this.bot.service.id,
          this.$route.query.version
        ])
        await this.GET_VERSION_INTENTS(this.bot.activeVersion)
      }
      this.showConditions = true
    } catch (error) {
      console.log(error)
    } finally {
      this.$vs.loading.close()
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#konecta-filters {
  position: relative;
  #kona-help-tooltip {
    position: absolute;
    right: -25px;
    top: -25px;
  }
}

.condition-select.vs-select-group h4 {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}

.konecta-filters {
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.85rem;
  }

  .vs-input--label {
    padding-left: 0;
  }

  .con-select .vs-select--input,
  .vs-input--input.normal {
    padding: 0.7rem;
    margin-top: 5px;
  }

  .vs-button--icon {
    z-index: 1;
  }

  &__name {
    width: auto;
  }

  &__new-condition {
    display: flex;
    flex-direction: column;

    .condition {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      padding: 3px 0;

      .con-select {
        label {
          text-transform: capitalize;
          padding: 0;
        }
      }

      .custom-select {
        display: flex;
        flex-direction: column;
        min-width: 50%;

        label {
          font-size: 0.85rem;
          color: rgba(0, 0, 0, 0.6);
        }

        // select {
        //   padding: 5px;
        //   font-size: 1rem;
        //   border: 1px solid rgba(0, 0, 0, 0.2);
        //   border-radius: 5px;
        //   padding-right: 20px;
        //   cursor: pointer;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   transition: all 0.2s ease;
        //   height: 38.5px;
        //   width: auto;
        //   margin-top: 5px;
        //   background-color: white;
        //   color: #626262;
        // }
      }

      &__items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 0;

        .item-row {
          display: flex;
          flex-direction: row;

          .cv-type {
            min-width: 50%;
          }

          div {
            // display: flex;
            // flex-direction: column;
            flex-grow: 1;

            &:not(:last-of-type):not(.multiselect__tags) {
              margin-right: 10px;
            }
          }
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .vs-divider {
    margin: 10px 0;
  }

  &__added-conditions {
    display: flex;
    flex-direction: column;
    transition: max-height 0.5s ease-in-out;

    .condition {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      padding: 3px 0;

      .con-select {
        label {
          text-transform: capitalize;
          padding: 0;
        }
      }

      .custom-select {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 160px;

        label {
          padding-left: 5px;
          font-size: 0.85rem;
          color: rgba(0, 0, 0, 0.6);
        }

        select {
          padding: 5px;
          font-size: 1rem;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding-right: 20px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: all 0.2s ease;
          height: 38.5px;
          width: auto;
          margin-top: 5px;
          background-color: white;
          color: #626262;
        }
      }

      &__operator {
        display: flex;
        justify-content: center;
        .multiselect {
          width: unset;
        }
      }

      &__items {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 3px;
        margin-top: 10px;

        .item-row {
          display: flex;
          flex-direction: row;

          .cv-type {
            min-width: 50%;
          }

          div {
            // display: flex;
            // flex-direction: column;
            flex-grow: 1;

            &:not(:last-of-type):not(.multiselect__tags) {
              margin-right: 10px;
            }
          }
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }

        .remove-button {
          position: absolute;
          top: -14px;
          right: -14px;
          border-radius: 3px;
        }
      }
    }
  }

  &__settings {
    .condition-tags-label {
      position: relative;
      #kona-help-tooltip {
        right: -35px !important;
        top: -7px !important;
      }
    }
  }
}
.vs-select-group {
  h4 {
    font-size: 1.2em;
    padding: 0;
  }
  li {
    margin-left: 10px !important;
  }
}

// vue-multiselect
.multiselect {
  margin-top: 4px;
  max-height: 38px;
  min-width: 50%;
}

.multiselect__option {
  padding: 10px;
  min-height: 30px;

  &--disabled {
    color: black !important;
    font-weight: bold;
  }
}

.multiselect__option--highlight {
  background: rgba(var(--vs-primary), 1) !important;
}

.multiselect__single,
.multiselect__placeholder,
.multiselect__input,
.multiselect__option,
.multiselect__tags {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.multiselect__select,
.multiselect__tags {
  display: block !important;
}

.multiselect__content-wrapper {
  z-index: 999 !important;

  .slot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    .actions {
      padding: 0 0 10px 0;
      display: flex;
      flex-direction: row !important;
      flex-grow: 1;
      justify-content: space-evenly !important;

      .vs-button {
        max-width: 100px;
      }
    }
  }
}

.multiselect__input {
  padding: 0;
}

.multiselect--disabled .multiselect__select {
  background: white;
  margin-right: 0 !important;
}

.multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
}
.input-icon-validate {
  display: none;
}
</style>
